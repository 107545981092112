import React, { Component } from "react";
import { GoLinkExternal } from "react-icons/go";
import { initFlowbite } from "flowbite";
import TopHeader from "./header";
import { FaXTwitter } from "react-icons/fa6";

type Props = {
  title: string;
  children: any;
};

export default class Layout extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    initFlowbite();
  }

  componentDidUpdate(prevProps: Readonly<Props>, snapshot?: any) {}

  render() {
    return <>{this.props.children}</>;
  }
}
